var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fragment',[_c('v-card-text',{staticClass:"pa-0"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.getPublicKeys,"item-key":"fingerprint","sort-by":['started_at'],"sort-desc":[true],"items-per-page":10,"footer-props":{'items-per-page-options': [10, 25, 50, 100]},"server-items-length":_vm.getNumberPublicKeys,"options":_vm.pagination,"disable-sort":true,"data-test":"publickeyList-dataTable"},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.fingerprint",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.fingerprint)+" ")]}},{key:"item.hostname",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.hostname)+" ")]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.created_at,"ddd, MMM Do YY, h:mm:ss a"))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('PublicKeyFormDialog',{attrs:{"key-object":item,"create-key":false},on:{"update":_vm.refresh}}),_c('PublicKeyDelete',{attrs:{"fingerprint":item.fingerprint},on:{"update":_vm.refresh}})]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }