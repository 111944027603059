var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fragment',[(_vm.createKey)?_c('v-tooltip',{attrs:{"bottom":"","disabled":_vm.isOwner || _vm.action == 'private'},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-btn',{staticClass:"v-btn--active",attrs:{"disabled":!_vm.isOwner && _vm.action == 'public',"text":"","color":"primary","data-test":"createKey-btn"},on:{"click":function($event){_vm.dialog = !_vm.dialog}}},[_vm._v(" Add "+_vm._s(_vm.action)+" Key ")])],1)]}}],null,false,1091705468)},[_c('span',[_vm._v(" You are not the owner of this namespace ")])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_c('v-icon',_vm._g({attrs:{"disabled":!_vm.isOwner && _vm.action == 'public'},on:{"click":function($event){_vm.dialog = !_vm.dialog}}},on),[_vm._v(" edit ")])],1)]}}])},[_c('div',[(_vm.isOwner || _vm.action == 'private')?_c('span',{attrs:{"data-test":"text-tooltip"}},[_vm._v(" Edit ")]):_c('span',[_vm._v(" You are not the owner of this namespace ")])])]),_c('v-dialog',{attrs:{"max-width":"400"},on:{"click:outside":_vm.close},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"data-test":"keyFormDialog-card"}},[_c('ValidationObserver',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [(_vm.createKey)?_c('v-card-title',{staticClass:"headline grey lighten-2 text-center"},[_vm._v(" New "+_vm._s(_vm.action)+" key ")]):_c('v-card-title',{staticClass:"headline grey lighten-2 text-center"},[_vm._v(" Edit "+_vm._s(_vm.action)+" key ")]),_c('v-card-text',[_c('ValidationProvider',{ref:"providerName",attrs:{"vid":"name","name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Name","error-messages":errors,"required":""},model:{value:(_vm.keyLocal.name),callback:function ($$v) {_vm.$set(_vm.keyLocal, "name", $$v)},expression:"keyLocal.name"}})]}}],null,true)}),(_vm.action == 'public')?_c('ValidationProvider',{attrs:{"name":"Hostname"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Hostname","error-messages":errors},model:{value:(_vm.keyLocal.hostname),callback:function ($$v) {_vm.$set(_vm.keyLocal, "hostname", $$v)},expression:"keyLocal.hostname"}})]}}],null,true)}):_vm._e(),_c('ValidationProvider',{ref:"providerData",attrs:{"vid":"key","name":"Data","rules":("required|parseKey:" + _vm.action),"disabled":!_vm.createKey},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":"Data","error-messages":errors,"required":"","disabled":!_vm.createKey,"messages":_vm.supportedKeys},model:{value:(_vm.keyLocal.data),callback:function ($$v) {_vm.$set(_vm.keyLocal, "data", $$v)},expression:"keyLocal.data"}})]}}],null,true)})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","data-test":"cancel-btn"},on:{"click":_vm.close}},[_vm._v(" Cancel ")]),(_vm.createKey)?_c('v-btn',{attrs:{"text":"","data-test":"create-btn"},on:{"click":function($event){return passes(_vm.create)}}},[_vm._v(" Create ")]):_c('v-btn',{attrs:{"text":"","data-test":"edit-btn"},on:{"click":function($event){return passes(_vm.edit)}}},[_vm._v(" Edit ")])],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }